import * as React from "react";
import { useAuth } from "@aws-amplify/ui-react/internal";
import { getOverrideProps, useNavigateAction } from "./utils";
import {
  Button,
  CheckboxField,
  Flex,
  Text,
  TextAreaField,
  TextField,
  Link
} from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function ContactUs(props) {
  const { heroModel, overrides, ...rest } = props;
  const authAttributes = useAuth().user?.attributes ?? {};
  const sendMessageClick = useNavigateAction({ type: "url", url: "/send-message" });
  const instagramOnClick = useNavigateAction({ type: "url", url: "/instagram" });
  const twitterOnClick = useNavigateAction({ type: "url", url: "/twitter" });
  const facebookOnClick = useNavigateAction({ type: "url", url: "/facebook" });
  const linkedInOnClick = useNavigateAction({ type: "url", url: "/linkedin" });
  return (
    <Flex
      gap="24px"
      direction="column"
      width={{ base: "auto", large: "auto" }}
      height={{ base: "auto", large: "auto" }}
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      //borderRadius="10px"
      padding={{ base: "10px 50px", large: "80px 400px 40px 400px" }}
      //boxShadow="3px 3px 30px 3px #FF4081" /* Added box shadow */
      {...getOverrideProps(overrides, "ContactUs")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width={{ base: "auto", large: "auto" }}
        height={{ base: "auto", large: "auto" }}
        justifyContent="flex-start"
        alignItems="flex-start"
        position="relative"
        borderRadius="10px"
        padding={{ base: "30px 20px", medium: "40px 20px", large: "40px 100px 40px 100px" }}
        boxShadow="0 0 10px rgba(255, 255, 255, 0.5)" /* Added box shadow */
        {...getOverrideProps(overrides, "ContactUs")}
        {...rest}
      >
        <Flex
          gap="24px"
          direction={{ base: "column", large: "row" }}
          width={{ base: "auto", large: "auto" }}
          height={{ base: "auto", large: "auto" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          borderRadius="10px"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 428")}
        >
          <TextField
            width={{ base: "auto", large: "auto" }}
            height={{ base: "auto", large: "auto" }}
            label="First name"
            grow="1"
            color="black"
            shrink="1"
            basis="0"
            placeholder={heroModel?.name}
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField29766936")}
          ></TextField>
          <TextField
            width={{ base: "auto", large: "auto" }}
            height={{ base: "auto", large: "auto" }}
            label="Last name"
            color="black"
            grow="1"
            shrink="1"
            basis="0"
            placeholder={heroModel?.name}
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField29766938")}
          ></TextField>
        </Flex>
        <TextField
          width={{ base: "auto", large: "auto" }}
          height={{ base: "auto", large: "auto" }}
          label="Email address"
          shrink="0"
          color="black"
          alignSelf="stretch"
          placeholder={authAttributes["email"]}
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField29766939")}
        ></TextField>
        <TextAreaField
          width={{ base: "auto", large: "auto" }}
          height={{ base: "auto", large: "auto" }}
          color="black"
          label="Message"
          shrink="0"
          alignSelf="stretch"
          placeholder=""
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextAreaField")}
        ></TextAreaField>
        <CheckboxField
          width={{ base: "auto", large: "auto" }}
          height={{ base: "auto", large: "auto" }}
          label="You agree to our Privacy Policy"
          shrink="0"
          size="default"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="end"
          accept=""
          {...getOverrideProps(overrides, "CheckboxField")}
        ></CheckboxField>
        <Link to="/send-message">
          <Button
            width={{ base: "auto", large: "auto" }}
            height={{ base: "auto", large: "auto" }}
            shrink="0"
            alignSelf="stretch"
            size="large"
            backgroundColor="#ed3e79"
            isDisabled={false}
            variation="primary"
            children="Send message"
            justifyContent="center"
            onClick={() => {
              sendMessageClick();
            }}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Link>
        <Flex
          gap="24px"
          direction={{ base: "column", large: "row" }}
          width={{ base: "auto", large: "auto" }}
          height={{ base: "auto", large: "auto" }}
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2829766941")}
        >
          <Flex
            gap="8px"
            direction="row"
            width={{ base: "auto", large: "auto" }}
            height={{ base: "auto", large: "auto" }}
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 2829766942")}
          >
            <MyIcon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="instagram"
              {...getOverrideProps(overrides, "MyIcon39193120")}
            ></MyIcon>
            <Link to="/instagram">
              <Text
                fontFamily="Helvetica"
                fontSize={{ base: "12px", medium: "13px", large: "14px" }}
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width={{ base: "auto", large: "auto" }}
                height={{ base: "auto", large: "auto" }}
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Instagram"
                onClick={() => {
                  instagramOnClick();
                }}
                {...getOverrideProps(overrides, "Instagram")}
              ></Text>
            </Link>
          </Flex>
          <Flex
            gap="8px"
            direction="row"
            width={{ base: "auto", large: "auto" }}
            height={{ base: "auto", large: "auto" }}
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 30")}
          >
            <MyIcon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="twitter"
              {...getOverrideProps(overrides, "MyIcon39193123")}
            ></MyIcon>
            <Link to="/twitter">
              <Text
                fontFamily="Helvetica"
                fontSize={{ base: "12px", medium: "13px", large: "14px" }}
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width={{ base: "auto", large: "auto" }}
                height={{ base: "auto", large: "auto" }}
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Twitter"
                onClick={() => {
                  twitterOnClick();
                }}
                {...getOverrideProps(overrides, "Twitter")}
              ></Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}