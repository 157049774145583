import * as React from "react";
import { useAuth } from "@aws-amplify/ui-react/internal";
import { getOverrideProps, useNavigateAction } from "./utils";
import {
  Button,
  Divider,
  Flex,
  Text,
  TextField,
  View,
  Link
} from "@aws-amplify/ui-react";
export default function MarketingFooter(props) {
  const { frame403, overrides, ...rest } = props;
  const authAttributes = useAuth().user?.attributes ?? {};
  const buttonOnClick = useNavigateAction({
    type: "url",
    url: authAttributes["email"],
  });
  const frameFourZeroThreeOnClick = useNavigateAction({ type: "url", url: "" });
  const productsOnClick = useNavigateAction({ type: "url", url: "/products" });
  const safariOnClick = useNavigateAction({ type: "url", url: "/safari" });
  const analyticsOnClick = useNavigateAction({ type: "url", url: "/analytics" });
  const contactCenterOnClick = useNavigateAction({ type: "url", url: "/contact-center" });
  const termsOnClick = useNavigateAction({ type: "url", url: "/terms" });
  const frameFourZeroFourOnClick = useNavigateAction({ type: "url", url: "" });
  const resourcesOnClick = useNavigateAction({ type: "url", url: "" });
  const docsOnClick = useNavigateAction({ type: "url", url: "/docs" });
  const learnOnClick = useNavigateAction({ type: "url", url: "/learn" });
  const privacyOnClick = useNavigateAction({ type: "url", url: "/privacy" });
  const changelogOnClick = useNavigateAction({ type: "url", url: "" });
  const frameFourZeroFiveThreeNineFourOneThreeNineSevenTwoOnClick =
    useNavigateAction({ target: "_blank", type: "url", url: "" });
  const companyOnClick = useNavigateAction({ type: "url", url: "/company" });
  const aboutusOnClick = useNavigateAction({ type: "url", url: "/about" });
  const contactOnClick = useNavigateAction({ type: "url", url: "/contact" });
  const blogOnClick = useNavigateAction({ type: "url", url: "/blog" });
  return (
    <Flex
      gap="32px"
      direction="column"
      width={{ base: "auto", large: "auto" }}
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding={{ base: "10px", large: "40px 40px 40px 40px" }}
      //backgroundColor="#61d0bc"
      //backgroundColor="rgba(255, 255, 255, 1)"
      {...getOverrideProps(overrides, "MarketingFooter")}
      {...rest}
    >
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider39413816")}
      ></Divider>
      <Flex
        gap="24px"
        direction={{ base: "column", large: "row" }}
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 40529766988")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          onClick={() => {
            frameFourZeroThreeOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 403")}
        >
          <Link to="/products">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="600"
              color="#4A148C"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.5px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Products"
              onClick={() => {
                productsOnClick();
              }}
              {...getOverrideProps(overrides, "Products")}
            ></Text>
          </Link>
          <Link to="/safari-ai">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Safari AI"
              onClick={() => {
                safariOnClick();
              }}
              {...getOverrideProps(overrides, "Safari AI")}
            ></Text>
          </Link>
          <Link to="/analytics">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Analytics"
              onClick={() => {
                analyticsOnClick();
              }}
              {...getOverrideProps(overrides, "Analytics")}
            ></Text>
          </Link>
          <Link to="/contact-center">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Contact Center"
              onClick={() => {
                contactCenterOnClick();
              }}
              {...getOverrideProps(overrides, "Contact Center")}
            ></Text>
          </Link>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          onClick={() => {
            frameFourZeroFourOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 404")}
        >
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="600"
            color="#4A148C"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.5px"
            width="272px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Resources"
            onClick={() => {
              resourcesOnClick();
            }}
            {...getOverrideProps(overrides, "Resources")}
          ></Text>
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="272px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Docs"
            onClick={() => {
              docsOnClick();
            }}
            {...getOverrideProps(overrides, "Docs")}
          ></Text>
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="272px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Learn"
            onClick={() => {
              learnOnClick();
            }}
            {...getOverrideProps(overrides, "Learn")}
          ></Text>
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="272px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Changelog"
            onClick={() => {
              changelogOnClick();
            }}
            {...getOverrideProps(overrides, "Changelog")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          onClick={() => {
            frameFourZeroFiveThreeNineFourOneThreeNineSevenTwoOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 40539413972")}
        >
          <Link to="/company">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="600"
              color="#4A148C"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.5px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Company"
              onClick={() => {
                companyOnClick();
              }}
              {...getOverrideProps(overrides, "Company")}
            ></Text>
          </Link>
          <Link to="/about">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="About"
              onClick={() => {
                aboutusOnClick();
              }}
              {...getOverrideProps(overrides, "About")}
            ></Text>
          </Link>
          <Link to="/terms">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms"
              onClick={() => {
                termsOnClick();
              }}
              {...getOverrideProps(overrides, "Terms")}
            ></Text>
          </Link>
          <Link to="/privacy">
            <Text
              fontFamily="Helvetica"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Privacy"
              onClick={() => {
                privacyOnClick();
              }}
              {...getOverrideProps(overrides, "Privacy")}
            ></Text>
          </Link>
        </Flex>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider39413869")}
      ></Divider>
      <Flex
        gap="0"
        direction={{ base: "column", large: "row" }}
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 433")}
      >
        <View
          width="127.88px"
          height="18.91px"
          {...getOverrideProps(overrides, "LogoWithText")}
        ></View>
        <Text
          fontFamily="Helvetica"
          fontSize="16px"
          fontWeight="300"
          color="rgba(0,0,0,1)"
          //color="white"
          lineHeight="24px"
          textAlign="right"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="© 2024 Voyage Companion Ltd. All rights reserved."
          {...getOverrideProps(
            overrides,
            "© 2024 Voyage Companion Ltd. All rights reserved."
          )}
        ></Text>
      </Flex>
    </Flex>
  );
}