import React from 'react';
import './style.css';
import '@aws-amplify/ui-react/styles.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="section company-overview">
        <h1>About Voyage Companion</h1>
        <div className="divider"></div>
        <p className="description">
          Voyage Companion is an innovative AI startup dedicated to revolutionizing the travel industry. Our diverse team blends AI technology expertise with deep travel industry knowledge to enhance and automate travel agency operations, ensuring exceptional customer satisfaction. We specialize in developing online conversational software, including AI chatbots, voicebots, website live chat, and WhatsApp Business solutions. Our tailored solutions empower travel professionals to deliver efficient and effective customer experiences, revolutionizing the way travel agents serve their clients.
        </p>
      </div>

      <div className="divider"></div>
      <div className="section goals-mission-vision">
        <div className="card">
          <h4>Goal</h4>
          <p>To empower travel agents by automating repetitive tasks with advanced AI technology, enabling them to focus on building strong client relationships and creating exceptional travel experiences.</p>
        </div>
        <div className="card">
          <h4>Mission</h4>
          <p>To revolutionize the travel industry by integrating AI with travel expertise, automating search, bookings, and support. We empower travel agents to deliver outstanding experiences and build lasting relationships with clients.</p>
        </div>
        <div className="card">
          <h4>Vision</h4>
          <p>To create a future where travel agents are freed from mundane tasks through automation, allowing them to leverage their expertise and deliver unmatched customer satisfaction, driving industry excellence.</p>
        </div>
      </div>

      <div className="divider"></div>
      <div className="section culture">
        <h2>Our Culture</h2>
        <div className="core-values">
          <div className="card">
            <h3>Diversity</h3>
            <p>We value diverse perspectives and backgrounds to foster innovation.</p>
          </div>
          <div className="card">
            <h3>Transparency</h3>
            <p>We promote open communication and honesty in all our dealings.</p>
          </div>
          <div className="card">
            <h3>Resourcefulness</h3>
            <p>We find creative solutions to overcome challenges.</p>
          </div>
          <div className="card">
            <h3>Resilience</h3>
            <p>We persistently strive towards our goals despite setbacks.</p>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      
      <div className="section team">
        <h2>Meet Our Team</h2>
        <div className="team-cards">
          <div className="card">
            <img src="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/team/Jacques+.jpg" alt="Jacques Khisa" />
            <h5>Jacques Khisa</h5>
            <p>Co-Founder/CEO</p>
          </div>
          <div className="card">
            <img src="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/team/Sydney.jpg" alt="Sydney Eneremadu" />
            <h5>Sydney Eneremadu</h5>
            <p>Co-Founder/Head of Business & Growth</p>
          </div>
          <div className="card">
            <img src="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/team/Shirllie.jpg" alt="Shirllie Apiyo" />
            <h5>Shirllie Apiyo</h5>
            <p>Head of Product & Marketing</p>
          </div>
        </div>
      </div>

      <div className="divider"></div>
      <div className="section affiliations">
        <h2>Affiliations</h2>
        <p>Voyage Companion is part of the NVIDIA Inception Program and AWS Activate for Startups, which provide us with resources and support to accelerate our growth and innovation.</p>
      </div>
      <div className="divider"></div>
      <div className="section contact-info">
        <h2>Contact Information</h2>
        <div className="card">
          <h3>Email</h3>
          <p>Primary Contact: <a href="mailto:info@voyagecompanion.online">info@voyagecompanion.online</a></p>
          <p>Support: <a href="mailto:support@voyagecompanion.online">support@voyagecompanion.online</a></p>
          <p>Careers: <a href="mailto:careers@voyagecompanion.online">careers@voyagecompanion.online</a></p>
          <p>Sales: <a href="mailto:sales@voyagecompanion.online">sales@voyagecompanion.online</a></p>
          <h3>Location</h3>
          <p>Pinetree Plaza, Kaburu Drive.</p>
          <p>P.O. Box Number 00629 – 00100 Nairobi, Kenya.</p>
        </div>
      </div>

      <div className="divider"></div>
    </div>
  );
};
export default AboutUs;
