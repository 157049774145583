import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Image, Text, Divider } from "@aws-amplify/ui-react";
export default function HeroLayout1(props) {
  const { heroModel, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Eyebrow: {},
        Heading: {},
        Body: {},
        Message: {},
        Button: {},
        HeroMessage: {},
        Left: {},
        image: {},
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Light" },
    },
    {
      overrides: {
        Eyebrow: { color: "rgba(255,255,255,1)" },
        Heading: {
          color: "rgba(255,255,255,1)",
          children:
            "Personalize, streamline operations, and improve customer experiences.",
        },
        Body: {
          color: "rgba(255,255,255,1)",
          children:
            "At Voyage Companion we are redefiining travel agents as knowledgeable advisors, liberated from mundane tasks by automation. This liberation empowers them to craft bespoke,unforgettable travel experiences for their clients. ",
        },
        Message: {},
        Button: {},
        HeroMessage: {},
        Left: { backgroundColor: "rgba(125,214,232,1)" },
        image: { width: "unset", alignSelf: "stretch" },
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Dark" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const eyebrowOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: heroModel?.id,
  });
  const buttonOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/chat",
  });
  return (
    <Flex
      gap="0"
      direction={{ base: "column", large: "row" }}
      width={{ base: "auto", large: "600px" }}
      height={{ base: "auto", large: "600px" }}
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding={{ base: "10px", large: "10px 40px 40px 40px" }}
      display="flex"
      {...getOverrideProps(overrides, "HeroLayout1")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width={{ base: "auto", large: "600px" }}
        height={{ base: "200px", medium: "300px", large: "450px" }}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        boxShadow="2px 2px 1px rgb(0,0,0,0.5)"
        border="1px solid #cff3ed"
        borderRadius={{ base: "30px 30px 0px 0px", large: "30px 0px 0px 30px" }}
        padding="60px"
        backgroundColor="#cff3ed"
        display="flex"
        {...getOverrideProps(overrides, "Right")}
      >
        <Image
          width="720px"
          height={{ base: "200px", medium: "300px", large: "500px" }}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="30px 0px 30px 10px"
          objectFit="contain"
          src="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/web/self1.png"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width={{ base: "auto", large: "600px" }}
        height={{ base: "auto", large: "450px" }}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        boxShadow="2px 2px 1px rgb(0,0,0,0.5)"
        border="1px solid #cff3ed"
        borderRadius={{ base: "0px 0px 30px 30px", large: "0px 30px 30px 0px" }}
        position="relative"
        padding="50px"
        backgroundColor="#cff3ed"
        display="flex"
        {...getOverrideProps(overrides, "Left")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="100%"
          justifyContent="center"
          alignItems="center"
          padding="0px"
          display="flex"
          {...getOverrideProps(overrides, "HeroMessage")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="100%"
            justifyContent="center"
            alignItems="center"
            padding="0px"
            display="flex"
            {...getOverrideProps(overrides, "Message")}
          >
            <Text
              fontFamily="Helvetica"
              fontSize={{ base: "16px", medium: "18px" }}
              fontWeight="800"
              color="#4A148C"
              lineHeight="1.5"
              textAlign="left"
              display="block"
              width="100%"
              padding="5px 10px"
              whiteSpace="pre-wrap"
              children="SELF SERVICE"
              onClick={() => {
                eyebrowOnClick();
              }}
              {...getOverrideProps(overrides, "Eyebrow")}
            ></Text>
            <Text
              fontFamily="Helvetica"
              fontSize={{ base: "16px", medium: "18px" }}
              fontWeight="500"
              letterSpacing="0.5px"
              lineHeight="1.5"
              color="rgba(0,0,0,1)"
              textAlign="left"
              display="block"
              width="100%"
              padding="10px 10px"
              whiteSpace="pre-wrap"
              children="Enable customers to complete the task they need whenever and wherever they want in a cost-efficient manner"
              {...getOverrideProps(overrides, "Heading")}
            ></Text>
            <Text
              fontFamily="Helvetica"
              fontSize={{ base: "16px", medium: "18px" }}
              fontWeight="300"
              letterSpacing="0.01px"
              lineHeight="1.5"
              color="rgba(0,0,0,1)"
              textAlign="left"
              display="block"
              width="100%"
              padding="10px 10px"
              whiteSpace="pre-wrap"
              children="Safari AI ensures seamless experiences and significant cost savings through efficient resource utilization and advanced automation."
              {...getOverrideProps(overrides, "Body")}
            ></Text>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              justifyContent="left"
              alignItems="left"
              padding="10px"
              boxShadow="0px 3px 3px rgb(0,0,0, 0.5)"
              backgroundColor="#ed3e79"
              size="medium"
              isDisabled={false}
              variation="primary"
              children="Automate"
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
