
import {
  BrowserRouter as Router,
  Link as ReactRouterLink,
  Routes,
  Route,
} from 'react-router-dom';
import { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { useAuthenticator, Authenticator, View, Image, useTheme, Text, Heading, Footer, Button  } from '@aws-amplify/ui-react';
import awsExports from '../../aws-exports';
import HomePage from '../home/HomePage';
import LoginPage from '../auth/LoginPage';
import RegisterPage from '../auth/RegisterPage';
import ValidatePage from '../auth/ValidatePage';
//import './App.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css';
import { 
 NavBarHeader2 ,
 Features2x2,
 HeroLayout1, 
 HeroLayout2, 
 MarketingPricing,
 CTASection, 
 MarketingFooter, 
 HeroLayout3, 
 MarketingFooterBrand, 
 NavBarSide,
 SideBar,
 ContactUs
} from '../../ui-components';



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function updateAuthStatus(authStatus) {
    setIsAuthenticated(authStatus);
  }

  return (
    <div className="App">
      
      <NavBarHeader2 width={'100vw'} isAuthenticated={isAuthenticated} updateAuthStatus={updateAuthStatus} />
      <HeroLayout1 width={'100vw'} />
      <Features2x2 width={'100vw'} />
      <MarketingFooterBrand width={'100vw'} />
      <NavBarSide width={'100vw'} />
      <SideBar width={'100vw'} />
      <HeroLayout2 width={'100vw'} />
      <MarketingFooter width={'100vw'} />
    </div>
  );
}

export default App;


