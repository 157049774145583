import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../App.css';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function ValidatePage() {
    const navigate = useNavigate();
    
    const [username, setUserName] = useState('');
    const [authenticationCode, setAuthenticationCode] = useState('');

    const handleRegisterConfirmation = async () => {
        try {
            console.log('handleRegisterConfirmation');
            console.log(username);
            console.log(authenticationCode);

            await Auth.confirmSignUp(username, authenticationCode);
            navigate('/login');
        } catch (err) { 
            console.log(err);
        }
    };

    return (
        <Container className="validate-container">
            <Row className="justify-content-md-center">
                <Col xs={12} md={6} className="validate-form">
                    <h1 className="text-center my-4">Validate</h1>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter User Name"
                                onChange={evt => setUserName(evt.target.value)} 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Authentication Code</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter Authentication Code"
                                onChange={evt => setAuthenticationCode(evt.target.value)} 
                            />
                        </Form.Group>

                        <div className="d-grid gap-2">
                            <Button 
                                variant="custom" 
                                type="button" 
                                onClick={handleRegisterConfirmation}
                                style={{ backgroundColor: "#ed3e79", color: '#fff', width: '50%', margin: '0 auto' }}
                            >
                                Confirm Sign Up
                            </Button>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <Link to='/'>
                                <Button 
                                    variant="outline-primary" 
                                    style={{ 
                                       // backgroundColor: '#ed3e79', 
                                        color: 'black',
                                        borderColor: '#ed3e79'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Link>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ValidatePage;
