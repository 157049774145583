/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Image, Text, Link } from "@aws-amplify/ui-react";
export default function HeroLayout2(props) {
  const { heroModel, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "VOYAGE COMPANION": {},
        "About Destina AI": {},
        "Our technology isn't just about simplifying bookings; it's about elevating the human touch. By automating routine tasks and integrating fintech solutions, we're freeing up time for agents to engage in what they do best: fostering authentic relationships and curating unique adventures.":
          {},
        "Main Body": {},
        Button: {},
        "Type Lock Up": {},
        HeroLayout2: {},
      },
      variantValues: { colorMode: "Default" },
    },
    {
      overrides: {
        "VOYAGE COMPANION": { color: "rgba(250,250,250,1)" },
        "About Destina AI": { color: "rgba(254,230,254,1)" },
        "Our technology isn't just about simplifying bookings; it's about elevating the human touch. By automating routine tasks and integrating fintech solutions, we're freeing up time for agents to engage in what they do best: fostering authentic relationships and curating unique adventures.":
          {
            color: "rgba(255,255,255,1)",
            children:
              "Our technology isn't just about simplifying bookings; it's about elevating the human touch. By automating routine tasks and integrating fintech solutions, we're freeing up time for agents to engage in what they do best: fostering authentic relationships and curating unique adventures.\n",
          },
        "Main Body": {},
        Button: {},
        "Type Lock Up": { shrink: "1", grow: "1", basis: "0" },
        HeroLayout2: { backgroundColor: "rgba(191,64,191,1)" },
      },
      variantValues: { colorMode: "dark" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const typeLockUpOnClick = useNavigateAction({ type: "url", url: "" });
  const buttonOnClick = useNavigateAction({ type: "url", url: "/contact" });
  return (
    <Flex
      gap="10px"
      direction="column"
      width={{ base: "auto", large: "600px" }}
      height={{ base: "auto", large: "400px" }}
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="0px 0px 0px 0px #4A148C"
      padding={{ base: "60px 20px", medium: "80px 40px", large: "160px 240px" }}
      display="flex"
      {...getOverrideProps(overrides, "HeroLayout1")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="100%"
        justifyContent="center"
        alignItems="center"
        padding="0px"
        display="flex"
        {...getOverrideProps(overrides, "Type Lock Up")}
      >
        <Text
          fontFamily="Helvetica"
          fontSize="16px"
          fontWeight="700"
          letterSpacing="0.5px"
          lineHeight="1.5"
          //color="#4A148C"
          //lineHeight="24px"
          color="#ed3e79"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="5px"
          whiteSpace="pre-wrap"
          children="APPLY AI"
          {...getOverrideProps(overrides, "APPLY RAG")}
        ></Text>
        <Flex
          gap="16px"
          direction="column"
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          padding="0px"
          display="flex"
          {...getOverrideProps(overrides, "Body")}
        >
          <Text
            fontFamily="Helvetica"
            fontSize={{ base: "24px", medium: "35px", large: "50px" }}
            fontWeight="300"
            color="#1d1160"//"#4A148C"
            letterSpacing="1px"
            lineHeight="2"
            textAlign="center"
            display="block"
            width="100%"
            padding="20px 0px 10px 0px"
            whiteSpace="pre-wrap"
            children="The Future Of Safari Starts Here."
            {...getOverrideProps(overrides, "The Future Of Safari Starts Here.")}
          ></Text>
        </Flex>
        <Link to="/contact">
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="medium"
            align="left"
            isDisabled={false}
            variation="primary"
            backgroundColor="#ed3e79"
            boxShadow="0px 4px 4px rgb(255,255,255, 0.5)"
            children="Book A Demo"
            onClick={() => {
              buttonOnClick();
            }}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Link>
      </Flex>
    </Flex>
  );
}
