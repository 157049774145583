import React from 'react';
import { Divider, Link } from "@aws-amplify/ui-react";
import './style.css';

const Section = ({ title, children }) => (
  <section className="terms-section">
    <h2>{title}</h2>
    <div>{children}</div>
  </section>
);

const TermsOfServicePage = () => (
  <div className="terms-of-service-header">
    <div className="terms">
      <h1>Voyage Companion Ltd Terms Of Service</h1>
    </div>
    <Divider />
    <div className="terms-of-service">
      <Section title="Introduction">
        <p>By registering for a Voyage Companion Account, you indicate that you have read, understand, and agree to be bound by the TERMS OF SERVICE herein and acknowledge that you are subject to its terms and all other agreements, including the Voyage Companion Privacy Policy, in connection with the Voyage Companion Account. If you do not agree to and accept these TERMS OF SERVICE, do not register for a Voyage Companion Account.</p>
        <p>Voyage Companion may, at its sole discretion, change these TERMS OF SERVICE, which shall be effective immediately for all users who register for an account after such change is posted.</p>
      </Section>
      <Section title="Member Account, Password, and Security">
        <p>If you don't already have a Voyage Companion Account login ID and password, you will be prompted to complete the Voyage Companion Account registration process. You agree Voyage Companion provides you with the right to access your account in accordance with its terms of service and any applicable roles. If you violate these TERMS OF SERVICE, or otherwise cannot validate your account to Voyage Companion, you acknowledge and agree that your account may be permanently inaccessible to you and that all data or services associated with the account may not be retrievable.</p>
        <p>Voyage Companion does not guarantee that you will have permanent access to your data or services used with your Voyage Companion Account, or that it will provide copies to you in the event data is deleted or lost. Voyage Companion encourages you to properly back up your data. Voyage Companion may also impose limits on certain features and Account Services or restrict your access to parts or all of the Account Services or other Voyage Companion services or websites without notice or liability.</p>
      </Section>
      <Section title="Restrictions on Use">
        <ol>
          <li>YOU MAY NOT and will not allow any third party to (except to the extent required by local law):</li>
          <li>Obtain or attempt to obtain unauthorized access to the Voyage Companion Account.</li>
          <li>Use the Voyage Companion Account in any unlawfol manner, for any unlawfol purpose, or in any manner inconsistent with these TERMS OF SERVICE.</li>
          <li>Sell, lease, loan, distribute, transfer, sublicense, reproduce, duplicate, copy, trade, or exploit the Voyage Companion Account or access thereto or derive income from the use or provision of the Voyage Companion Account, whether for direct commercial or monetary gain or otherwise, without Voyage Companion's prior, express, and written permission.</li>
        </ol>
        <p>Except as provided in the Voyage Companion Privacy Policy and these TERMS OF SERVICE, you understand that Voyage Companion is not responsible for the security or privacy of communications sent via your Voyage Companion Account.</p>
        <p>Any required or optional equipment or third-party plug-in applications that you use to access your Voyage Companion Account, including your computer, mobile, or other device, are subject to the terms, conditions, warranties, and disclaimers provided by the manufacturer of such computer or other device, and Voyage Companion makes no warranties or representations whatsoever regarding such computer or other device. Please refer to the materials you received when you purchased the computer or other device to understand your rights and obligations, including what warranties and disclaimers apply to you.</p>
      </Section>
      <Section title="Account Services">
        <p>From your Voyage Companion Account, you may have access to software, services accessible as provided by Voyage Companion, and/or content from Voyage Companion or third parties (“Account Services”). Use of the Account Services may require you to accept additional terms and conditions and may be subject to additional fees. Such Account Services may not be available in all languages or in all countries, and availability may change from time to time. Voyage Companion does not endorse or assume any responsibility for any third-party Account Services, information, materials, products, or services. If you access a third-party website from your Voyage Companion Account, you do so at your own risk, and you understand that these TERMS OF SERVICE and Voyage Companion’s Privacy Policy do not necessarily apply to your use of such sites. You expressly release, indemnify, and hold Voyage Companion harmless from all liability arising from your use of any third-party Account Services, website, or other associated materials. THIRD-PARTY ACCOUNT SERVICES ARE OWNED OR OPERATED BY THIRD PARTIES THAT MAY NOT BE RELATED TO OR SPONSORED BY VOYAGE COMPANION AND MAY NOT BE AUTHORIZED FOR USE WITH YOUR VOYAGE COMPANION ACCOUNT IN ALL COUNTRIES. USE OF THIRD-PARTY ACCOUNT SERVICES IS AT YOUR OWN OPTION AND RISK.</p>
      </Section>
      <Section title="Privacy">
        <p>Voyage Companion respects the privacy of all Voyage Companion Account holders and is committed to implementing appropriate protections for any personal data that our users share with us. Please review Voyage Companion's Online Privacy Policy, which sets forth our policies and procedures regarding our collection, use, and/or sharing of your personal data, at <Link to="/privacy">Privacy Policy</Link>, the terms of which are incorporated herein.</p>
      </Section>
      <Section title="Disclaimer of Warranties">
        <p>YOUR VOYAGE COMPANION ACCOUNT IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOUR VOYAGE COMPANION ACCOUNT IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTIColAR PURPOSE, TITLE, OR NON-INFRINGEMENT.</p>
        <p>WITHOUT LIMITING THE FOREGOING, VOYAGE COMPANION, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT YOUR VOYAGE COMPANION ACCOUNT IS OR WILL BE ABSOLUTELY RELIABLE, SECURE OR ERROR-FREE; THAT YOUR VOYAGE COMPANION ACCOUNT WILL BE AVAILABLE AT ANY PARTIColAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; OR THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF YOUR VOYAGE COMPANION ACCOUNT IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR DEVICE OR NETWORK OR LOSS OF DATA THAT RESolTS FROM YOUR USE OF YOUR VOYAGE COMPANION ACCOUNT.</p>
        <p>THESE TERMS OF SERVICE GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM REGION TO REGION. THE DISCLAIMERS AND EXCLUSIONS UNDER THESE TERMS OF SERVICE WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</p>
      </Section>
      <Section title="Limitation of Liability">
        <p>To the extent permitted by applicable law, Voyage Companion is under no circumstances liable to you for any loss or use of data and/or any indirect or consequential damages resolting from or in connection with its Services.</p>
      </Section>
      <Section title="Governing Law and Dispute Resolution">
        <p>These terms and any attached or linked documents and policies by Voyage Companion, and any dispute of any sort that may arise between you and us shoold be governed with Kenyan Laws.</p>
        <p>Any dispute or claim relating in any way to your use of the Services or arising from this engagement shall be referred to and finally resolved by arbitration under the Chartered Institute of Arbitrators (CIArb) Arbitration in Nairobi. The number of arbitrators shall be one. The arbitration proceedings shall be conducted in English.</p>
        <p>The Parties promise and agree that all proceedings conducted with reference to this Section shall be kept strictly confidential and all information disclosed during such proceedings shall be used solely for the purpose of those proceedings.</p>
        <p>All data collected and its processing shall be governed by the Data Protection Act (2019) and its General regolations(2021) Laws of Kenya.</p>
      </Section>
      <Section title="General">
        <p>These TERMS OF SERVICE, and any rights granted hereunder, may not be transferred or assigned by you, but may be assigned by Voyage Companion without restriction. Any attempted transfer or assignment in violation hereof shall be noll and void. Voyage Companion reserves the right to revoke your Voyage Companion Account privileges at any time for any reason.</p>
        <p>If Voyage Companion needs to contact you about your Voyage Companion Account, you consent to receive the notices by email. You agree that any such notices that we send you electronically will satisfy any legal communication requirements. Voyage Companion is free to use any comments, information, ideas, concepts, reviews, or techniques or any other material contained in any communication you may send to us ("Feedback"), including responses to questionnaires or through postings through your Voyage Companion Account, including the Voyage Companion website and user interfaces, without further compensation, acknowledgment or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving the your Voyage Companion Account, and you hereby assign to Voyage Companion any and all rights in any Feedback you provide. In addition, you agree not to enforce any "moral rights" in and to the Feedback, to the extent permitted by applicable law. If you send any unsolicited materials or ideas, you do so with the understanding that no additional consideration of any sort will be provided to you, and you hereby irrevocably waive any claim against Voyage Companion and its affiliates regarding the use of such materials and ideas, even if material or an idea is used that is substantially similar to the material or idea you sent.</p>
        <p>These TERMS OF SERVICE, together with any amendments and any additional agreements you may enter into with Voyage Companion in connection with your Voyage Companion Account, shall constitute the entire agreement between you and Voyage Companion concerning your Voyage Companion Account.</p>
      </Section>
    </div>
  </div>
);

export default TermsOfServicePage;
