import React from 'react';
import './style.css';
import '@aws-amplify/ui-react/styles.css';
import { Divider } from "@aws-amplify/ui-react";

const Section = ({ title, children }) => (
  <section className="policy-section">
    <h2>{title}</h2>
    <div>{children}</div>
  </section>
);

const PrivacyPolicyPage = () => (
  <div className="privacy-policy-header">
    <div className="privacy">
      <h1>Privacy Policy</h1>
    </div>
    <Divider />
    <div className="privacy-policy">
      <Section title="Introduction">
        <p>This Privacy Policy describes your rights under the General Data Protection Regulation (GDPR). It sets out how we will use and protect your personal data.</p>
        <p>We publish the latest version of our privacy policy on our website, and we will update this as and when necessary.</p>
        <p>We will contact you (by email) to notify you of these updates where:</p>
        <ol>
          <li>We are making substantial changes</li>
          <li>Where we are doing something with your personal information, which has not already been disclosed within this policy</li>
        </ol>
        <p>This policy is effective from 25th May 2018, the date in which GDPR formally came into force.</p>
      </Section>
      <Section title="Personal Data">
        <p>Under GDPR, personal data is defined as:</p>
        <blockquote>
          <p>“any information relating to an identified or identifiable natural person ('data subject'); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”.</p>
        </blockquote>
        <p>The personal data that this policy refers to relates to that of our potential and existing clients. This does not cover personal information provided by the end consumer to our clients when using any chatbots or similar products created with the use of our Services to our clients, as we do not control the means and purposes of such data processing as defined in Article 4(7) of GDPR. Typically, our clients, who are the owners of the chatbots / Voicebots created through our service, will be able to collect your conversation history with their chatbot / Voicebots and may contain information such as your name, email & telephone number for the website chatbot, and information contained in your social media profile for the Facebook Messenger chatbot. Please consult our clients’ privacy policies for such data processing activities.</p>
      </Section>
      <Section title="Personal Data That We Collect About You">
        <p>We may collect personal information on you from a number of sources:</p>
        <ol>
          <li><strong>Information you provide to us:</strong> this consists of information you provide to us voluntarily in the course of using our services, such as when visiting our website, communicating with us or in the process of requesting our services. You are free to choose which information you want to provide to us or whether you want to provide us with information at all. However, some information, such as your name and email address may be necessary for the performance of our contractual obligations. Without providing this information, you will not be able to order certain services or enter into a contract with us.</li>
          <li><strong>Information collected via cookies:</strong> a cookie is a piece of code which is used to uniquely identify your browser and track each site you visit that has Google Analytics enabled. We use this data to determine how many people visit our site and better understand how they find and use our web pages. We use this information to continually improve the effectiveness of our site. We can also use it to increase the number of new people finding out about our site. Cookies cannot be used to run programs or deliver viruses to your computer.</li>
          <li><strong>Personal Information we receive from others:</strong> we may receive personal information about users from third parties such as social media sites, law enforcement agencies and marketing consultants.</li>
        </ol>
      </Section>
      <Section title="How We Use Your Personal Data">
        <p>Your personal data may be used by us in the following ways:</p>
        <ol>
          <li><strong>Products & Services:</strong> in order to perform our contractual services during or prior to entering into a contract with you</li>
          <li><strong>Transactional purposes:</strong> in order to make payments or recover monies due to/from you</li>
          <li><strong>Marketing:</strong> where we have your consent to do so, to send promotional information about our current and new products & services via the standard communications channels i.e. post, email, telephone</li>
          <li><strong>Quality control:</strong> to ensure we maintain the highest standards of quality to our clients and their end consumers we may contact you from time-to-time to ensure you are satisfied with the quality of our service</li>
          <li><strong>Tax & Legal obligations:</strong> we are obligated to retain certain information because of legal requirements, for example tax or commercial laws.</li>
        </ol>
        <p>We will always ensure that the personal information we keep on you is:</p>
        <ol>
          <li>Kept to a minimum required in the course of doing business</li>
          <li>Will not be overly intrusive to you</li>
          <li>Will be proportionate based on the needs of our business</li>
        </ol>
      </Section>
      <Section title="How Long We Will Keep Your Personal Data">
        <p>Voyage Companion is required to retain information in accordance with the law, such as information needed for tax purposes. How long certain kinds of personal data should be kept may also be governed by specific business sector requirements and agreed practices. Voyage Companion will process and store personal data for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you.</p>
        <p>We retain collected cookie information for 5 years.</p>
        <p>Appropriate safeguards are in place to protect your data.</p>
      </Section>
      <Section title="Your Personal Data Rights">
        <p>You have the following rights regarding your personal data:</p>
        <ol>
          <li><strong>Right to Obtain:</strong> you have the right to obtain a copy of the personal data we hold about you. You can email us at Voyage Companion with the subject heading “Data Access Request” along with your name.</li>
          <li><strong>Right to Amend:</strong> you have the right to amend any personal data we may hold on you that you believe is wrong or incomplete. Please contact us at Voyage Companion if you want to do this with the subject heading “Data Amend Request” along with your name.</li>
          <li><strong>Right to Erasure:</strong> you have the right to request the deletion or removal of your personal information where there is no compelling reason for its continued processing by us.</li>
          <li><strong>Right to Restriction:</strong> you have the right to restrict our processing of your personal data in certain circumstances.</li>
          <li><strong>Right to Portability:</strong> you have the right to have the data we hold about you transferred to another organisation.</li>
          <li><strong>Right to Object:</strong> you have the right to object to certain types of processing, such as direct marketing.</li>
          <li><strong>Right to Object to Automated Decision Making (including Profiling):</strong> you have the right not to be subject to automated decision making, including profiling.</li>
          <li><strong>Right to Complain:</strong> you have the right to complain to the supervisory authority if you think any of your rights have been infringed by us.</li>
        </ol>
        <p>Voyage Companion reserves the right to request the following documentation as proof of ID when information on your personal data is requested:</p>
        <ol>
          <li>Signed passport</li>
          <li>Signed driving licence</li>
        </ol>
      </Section>
      <Section title="Contact Information">
        <p>If you wish to make a complaint or require some action regarding how your data is processed by Voyage Companion, you have the right to lodge a complaint directly with Voyage Companion. Write to us: <a href="mailto:privacy@voyagecompanion.online">privacy@voyagecompanion.online</a></p>
      </Section>
    </div>
  </div>
);

export default PrivacyPolicyPage;
