import React from 'react';
import {
  BrowserRouter as Router,
  Link as ReactRouterLink,
  Routes,
  Route,
} from 'react-router-dom';
import { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { useAuthenticator, Authenticator, View, Image, Link, Flex, useTheme, Text, Heading, Footer, Button  } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import ContactCenterPage from './pages/widget/ContactCenter';
import HomePage from './pages/home/HomePage';
import AboutPage from './pages/home/AboutPage';
import PrivacyPolicyPage from './pages/home/PrivacyPolicyPage';
import TermsOfServicePage from './pages/home/TermsOfServicePage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ValidatePage from './pages/auth/ValidatePage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css';
import { 
 NavBarHeader2 ,
 Features2x2,
 HeroLayout1, 
 HeroLayout2, 
 MarketingPricing,
 CTASection, 
 MarketingFooter, 
 HeroLayout3, 
 MarketingFooterBrand, 
 NavBarSide,
 SideBar,
 ContactUs
} from './ui-components';

//import Layout from './Layout';



function Layout({ children }) {
  return (
    <div>
      <NavBarHeader2 width={'100vw'} />
      <div className="content">
        {children}
      </div>
      <HeroLayout2 width={'100vw'} />
      <MarketingFooter width={'100vw'} />
    </div>
  );
}


function FooterLayout({ children }) {
  return (
    <div>
      <NavBarHeader2 width={'100vw'} />
      <div className="content">
        {children}
      </div>
      <MarketingFooter width={'100vw'} />
    </div>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function updateAuthStatus(authStatus) {
    setIsAuthenticated(authStatus);
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage isAuthenticated={isAuthenticated} />} />
        <Route path="/about" element={<Layout><AboutPage /></Layout>} />        
        <Route path="/contact" element={<FooterLayout><ContactUs /></FooterLayout>} />
        <Route path="/privacy" element={<Layout><PrivacyPolicyPage /></Layout>} />
        <Route path="/terms" element={<Layout><TermsOfServicePage /></Layout>} />
        <Route path="/login" element={<FooterLayout><LoginPage updateAuthStatus={updateAuthStatus} /></FooterLayout>} />
        <Route path="/register" element={<FooterLayout><RegisterPage /></FooterLayout>} />
        <Route path="/validate" element={<FooterLayout><ValidatePage /></FooterLayout>} />
        <Route path="*" element={<HomePage isAuthenticated={isAuthenticated} />} />
      </Routes>
    </Router>
  );
}

export default App;
