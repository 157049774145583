import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../App.css';
import '../../index.css';
import { Auth } from 'aws-amplify';
import { CheckboxField, Authenticator } from "@aws-amplify/ui-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '@aws-amplify/ui-react/styles.css';

function RegisterPage() {
    const navigate = useNavigate();

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [agree, setAgree] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleRegister = async () => {
        if (!agree) {
            setShowAlert(true);
            return;
        }
        setShowAlert(false);

        try {
            const { user } = await Authenticator.signUp({
                username: username,
                password: password,
                attributes: {
                    email: email,
                }
            });

            navigate('/validate');
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Container className="register-container" style={{ padding: '20px' }}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6} className="register-form">
                    <h1 className="text-center my-4">Register</h1>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter User Name"
                                onChange={evt => setUserName(evt.target.value)} 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter email"
                                onChange={evt => setEmail(evt.target.value)} 
                            />
                            <Form.Text className='text-muted'>
                                We'll never share your email!
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                minLength="8" 
                                placeholder="Enter Password"
                                onChange={evt => setPassword(evt.target.value)} 
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <CheckboxField
                                width="unset"
                                height="unset"
                                label={
                                    <>
                                        You agree to our 
                                        <Link to="/privacy"> Privacy Policy</Link>
                                    </>
                                }
                                shrink="0"
                                size="default"
                                defaultChecked={false}
                                isDisabled={false}
                                labelPosition="end"
                                accept=""
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                            />
                        </Form.Group>
                        {showAlert && <div className="alert alert-danger" role="alert">You must agree to the Privacy Policy to register.</div>}

                        <div className="d-grid gap-2">
                            <Button 
                                variant="custom" 
                                type="button" 
                                onClick={handleRegister}
                                style={{ 
                                    backgroundColor: '#ed3e79', 
                                    color: '#fff',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            >
                                Register
                            </Button>
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                            <Link to='/login'>
                                <Button 
                                    variant="outline-primary" 
                                    style={{ 
                                        //backgroundColor: '#ed3e79', 
                                        color: 'black',
                                        borderColor: '#ed3e79'
                                    }}
                                >
                                    Login
                                </Button>
                            </Link>
                            <Link to='/'>
                                <Button 
                                    variant="outline-primary" 
                                    style={{ 
                                       // backgroundColor: '#ed3e79', 
                                        color: 'black',
                                        borderColor: '#ed3e79'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Link>
                        </div>
                    </Form>
                  
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterPage;
