
import * as React from "react";
import { useState } from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text, Link } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

export default function NavBarHeader2(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { overrides, ...rest } = props;
  const imageOneOnClick = useNavigateAction({
    anchor: "/home",
    type: "anchor",
  });
  const homeOnClick = useNavigateAction({ type: "url", url: "/home" });
  const aboutUsOnClick = useNavigateAction({ type: "url", url: "/about" });
  const pricingOnClick = useNavigateAction({ type: "url", url: "/product" });
  const contactOnClick = useNavigateAction({ type: "url", url: "/contact" });
  const buttonThreeNineFourNineThreeFourSixSixOnClick = useNavigateAction({
    type: "url",
    url: "/login",
  });
  const buttonThreeNineFourNineThreeFourSixSevenOnClick = useNavigateAction({
    type: "url",
    url: "/register",
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Flex
      direction="column"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      padding={{ base: "16px", large: "16px 32px" }}
      boxShadow="0px 0px 2px 0px #64FFDA"
      {...getOverrideProps(overrides, "NavBarHeader2")}
      {...rest}
    >
      <Flex
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="0"
      >
        <Link to="/">
          <Image
            width={{ base: "auto", large: "150px" }}
            height={{ base: "auto", medium: "90px" }}
            display="block"
            objectFit="cover"
            src="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/visualGuide/secondary+logo+coloured+CMYK.png"
            onClick={() => {
              imageOneOnClick();
            }}
            {...getOverrideProps(overrides, "image 1")}
          />
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      </Flex>
      <Flex
        direction={{ base: 'column', small: isMenuOpen ? 'column' : 'row' }}
        width={{ base: '100%', small: 'auto', large: '700px' }}
        justifyContent="flex-end"
        alignItems={{ base: 'center', small: isMenuOpen ? 'center' : 'left'}}
        gap={{ base: "10px", medium: "32px" }}
        className={`actions ${isMenuOpen ? "menu-open" : ""}`}
        {...getOverrideProps(overrides, "actions")}
      >
        <Link to="/">
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="600"
            color="#4A148C"
            lineHeight="24px"
            textAlign="right"
            padding="0px 10px 0px 10px"
            whiteSpace="pre-wrap"
            children="Home"
            onClick={() => {
              homeOnClick();
            }}
            {...getOverrideProps(overrides, "Home")}
          />
        </Link>
        <Link to="/about">
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="600"
            color="#4A148C"
            lineHeight="24px"
            textAlign="right"
            padding="0px 10px 0px 10px"
            whiteSpace="pre-wrap"
            children="About Us"
            onClick={() => {
              aboutUsOnClick();
            }}
            {...getOverrideProps(overrides, "About Us")}
          />
        </Link>
        <Link to="/products">
          <Text
            fontFamily="Helvetica"
            fontSize="16px"
            fontWeight="600"
            color="#4A148C"
            lineHeight="24px"
            textAlign="right"
            padding="0px 10px 0px 10px"
            whiteSpace="pre-wrap"
            children="Products"
            onClick={() => {
              pricingOnClick();
            }}
            {...getOverrideProps(overrides, "Product")}
          />
        </Link>
        <Link to="/login">
          <Button
            size="medium"
            variation="link"
            color="#4A148C"
            children="Sign In"
            onClick={() => {
              buttonThreeNineFourNineThreeFourSixSixOnClick();
            }}
            {...getOverrideProps(overrides, "Button39493466")}
          />
        </Link>
        <Link to="/register">
          <Button
            size="medium"
            variation="primary"
            backgroundColor="#4A148C"
            boxShadow="0px 0px 3px 3px rgb(255,255,255, 0.5)"
            children="Get Started"
            onClick={() => {
              buttonThreeNineFourNineThreeFourSixSevenOnClick();
            }}
            {...getOverrideProps(overrides, "Button39493467")}
          />
        </Link>
      </Flex>
    </Flex>
  );
}
