import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';

import { ThemeProvider } from "@aws-amplify/ui-react";
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from "./ui-components";

// import { BrowserRouter } from 'react-router-dom';
// import { breakpoints } from './utils/breakpoints';


Amplify.configure(awsconfig);

Amplify.configure(config);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
<ThemeProvider theme={studioTheme}>
  <App />
</ThemeProvider>


// ReactDOM.render(
//   <BrowserRouter>
//     <App breakpoints={breakpoints} />
//   </BrowserRouter>,
//   document.getElementById('root')
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();