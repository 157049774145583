import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "./utils";
import { Button, Flex, Image, Text, Divider, Link } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

export default function HeroLayout1(props) {
  const { heroModel, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Eyebrow: {},
        Heading: {},
        Body: {},
        Message: {},
        Button: {},
        "Type Lock Up": {},        
        HeroMessage: {},
        Left: {},
        image: {},
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Light" },
    },
    {
      overrides: {
        Eyebrow: { color: "rgba(255,255,255,1)" },
        Heading: {
          color: "rgba(255,255,255,1)",
          children: "Streamline, Personalize & Automate",
        },
        Body: {
          color: "rgba(255,255,255,1)",
          children: "Empower Travel Professionals with Advanced AI. Real-Time, Personalized Assistance for Exceptional Service.",
        },
        Message: {},
        Button: {},
        "Type Lock Up": { shrink: "1", grow: "1", basis: "0" },
        HeroMessage: {},
        Left: { backgroundColor: "rgba(125,214,232,1)" },
        image: { width: "unset", alignSelf: "stretch" },
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Dark" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const eyebrowOnClick = useNavigateAction({
    type: "url",
    url: heroModel?.id,
  });
  const buttonOnClick = useNavigateAction({
    type: "url",
    url: "/contact",
  });

  return (
    <Flex
      gap="10px"
      direction={{ base: "column", large: "row" }}
      width="100%"
      height="auto"
      //backgroundImage="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/Untitled+design+(1).png"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="0px 0px 0px 0px #4A148C"
      padding={{ base: "60px 20px", medium: "80px 40px", large: "160px 240px" }}
      display="flex"
      {...getOverrideProps(overrides, "HeroLayout1")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="100%"
        justifyContent="center"
        alignItems="center"
        padding="0px 10px 0px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Type Lock Up")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          padding="0px"
          display="flex"
          {...getOverrideProps(overrides, "Body")}
        >
          <Text
            fontFamily="Helvetica"
            fontSize={{ base: "24px", medium: "35px", large: "50px" }}
            fontWeight="400"
            color="#4A148C"
            //color="black"
            letterSpacing="1px"
            lineHeight="1.3"
            textAlign="center"
            display="block"
            width="100%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Streamline, Personalize & Automate."
            {...getOverrideProps(overrides, "Heading")}
          ></Text>
          <Text
            fontFamily="Helvetica"
            fontSize={{ base: "16px", medium: "18px", large: "20px" }}
            fontWeight="350"
            //color="#1d1160"
            //color="#4A148C"
            color='#4A148C'
            lineHeight="1.5"
            textAlign={{ base: "center", medium: "center", large: "left" }}
            letterSpacing="1px"
            display="block"
            width="100%"
            padding="10px 0px 20px 0px"
            whiteSpace="pre-wrap"
            children="Voyage Companion empowers travel professionals to leverage human-like AI voice agents for exceptional customer service."
            {...getOverrideProps(overrides, "Body")}
          ></Text>
          <Link to="/contact">
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              //padding="5px 0px 10px 0px"
              align="left"
              isDisabled={false}
              variation="primary"
              backgroundColor="#ed3e79"
              boxShadow="0px 4px 4px rgb(255,255,255, 0.5)"
              children="Book A Demo"
              onClick={() => {
                buttonOnClick();
              }}
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Link>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width={{ base: "auto", large: "600px" }}
        height={{ base: "auto", large: "500px" }}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        //boxShadow="-1px 2px 1px rgb(0,0,0,0.5)"
        //border="1px solid #cff3ed"
        //borderRadius={{ base: "0px 0px 30px 30px", large: "0px 30px 30px 0px" }}
        padding="20px"
        //backgroundColor="#cff3ed"
        display="flex"
        {...getOverrideProps(overrides, "Right")}
      >
        <Image
          width="1000px"
          height={{ base: "auto", large: "500px" }}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="30px 0px 30px 10px"
          objectFit="contain"
          src="https://amplifyui.s3.eu-west-2.amazonaws.com/AmplifyUI/web/the_omni.png"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="large"
        orientation="horizontal"
        backgroundColor="#ed3e79"
      ></Divider>
    </Flex>
  );
}
